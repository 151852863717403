<style lang="less" scoped>
	img{
	  width: 100%;
	  height: 100%;
	}
.w {
  width: 1200px;
  margin: 0 auto;
  padding-bottom: 50px;
  box-sizing: border-box;
}
input {
  width: 80%;
  height: 40px;
  border-radius: 5px;
  border: 1px solid #ddd;
  padding-left: 10px;
  box-sizing: border-box;
}
.active {
  color: #0090f0;
}
.navbar {
  display: flex;
  align-items: center;
  width: 100%;
  height: 60px;
  font-size: 14px;
  .nav_item {
    cursor: pointer;
  }
  span {
    margin: 0 10px;
  }
  .now {
    color: #0090f0;
  }
}
.card {
  width: 100%;
  // height: 335px;
  background-color: #fff;
  padding: 35px;
  box-sizing: border-box;
  margin-bottom: 35px;
  .card_top {
    display: flex;
    align-items: center;
    > div:nth-child(1) {
      width: 66px;
      height: 26px;
      background: url("../../assets/img/course/img3.png") no-repeat;
      background-size: 100%, 100%;
      color: #fff;
      font-size: 14px;
      line-height: 26px;
      padding-left: 8px;
      box-sizing: border-box;
      margin-right: 10px;
    }
    > div:nth-child(2) {
      font-size: 26px;
    }
  }
  .card_money {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 58px;
    font-size: 14px;
    color: #666;
    background-color: rgba(204, 204, 204, 0.22);
    padding: 0 65px;
    box-sizing: border-box;
    margin: 30px 0;
    .mon_left {
      display: flex;
      align-items: flex-end;
      .live_psd {
        font-size: 20px;
        color: #999;
        margin-left: 10px;
      }
      .live_price {
        font-size: 26px;
        font-weight: 700;
        color: #f13232;
        margin-right: 10px;
        span {
          font-size: 16px;
        }
      }
      > div:nth-child(2) {
        margin-bottom: 4px;
      }
    }
    .mon_right {
      img {
        width: 16px;
        height: 18px;
        margin-right: 5px;
        vertical-align: top;
      }
    }
  }
  .card_time {
    font-size: 14px;
    color: #999;
    margin-left: 70px;
    > div {
      margin-bottom: 20px;
    }
    img {
      width: 18px;
      height: 18px;
      vertical-align: bottom;
      margin-right: 5px;
    }
    .btm {
      display: flex;
      span {
        margin: 0 30px;
      }
    }
    .card_btn {
      width: 160px;
      height: 50px;
      background: #f13232;
      border-radius: 6px;
      text-align: center;
      line-height: 50px;
      color: #fff;
      font-size: 20px;
      cursor: pointer;
      // margin-bottom: 20px;
    }
  }
}
.card_body {
  width: 100%;
  display: flex;
  justify-content: space-between;
  .body_left {
    width: 870px;
    background-color: #fff;
  }
  .body_right {
    width: 300px;
    height: 500px;
    background-color: #fff;
    .course_top {
      width: 100%;
      height: 66px;
      line-height: 66px;
      border-bottom: 1px solid #f2f2f2;
      font-size: 20px;
      font-weight: 700;
      padding: 0 20px;
      box-sizing: border-box;
      img {
        width: 24px;
        height: 18px;
        margin-right: 10px;
        vertical-align: middle;
      }
    }
    .course_body {
      padding: 20px;
      box-sizing: border-box;
      .course_item {
        display: flex;
        margin-bottom: 22px;
        cursor: pointer;
        .img {
          width: 130px;
          height: 78px;
          border-radius: 6px;
        }
        .rt {
          width: 44%;
          margin-left: 10px;
          > div:nth-child(1) {
            font-size: 14px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
          }
          > div:nth-child(2) {
            font-size: 12px;
            color: #999;
            margin-top: 22px;
          }
        }
      }
    }
  }
}
.tabs {
  font-size: 20px;
  font-weight: 500;
  color: #666666;
  div {
    cursor: pointer;
    text-align: center;
    width: 140px;
    height: 66px;
    line-height: 66px;
    margin-right: 70px;
    box-sizing: border-box;
  }
  .active2 {
    color: #0090f0;
    border-bottom: 4px solid #0090f0;
  }
}
.intro {
  padding: 20px;
}
</style>
<template>
  <div>
    <el-dialog
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <input v-model="livePsd" placeholder="请输入房间密码" />
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="goYY(live_info.id)"
          >立即预约</el-button
        >
      </span>
    </el-dialog>
    <div class="w">
      <!-- 面包屑 -->
      <div class="navbar"></div>
      <!-- 课程信息 -->
      <div class="card">
        <div class="card_top">
          <div>直播课</div>
          <div>{{ live_info.name }}</div>
        </div>
        <div class="card_money">
          <div class="mon_left">
            <div v-if="live_info.is_admission == 0">
              <div class="live_psd">密码房间</div>
            </div>
            <div v-else class="live_price">
              <span>￥</span>
              {{ live_info.price }}
            </div>
            <!-- <div>单买总价：<del> ￥5200</del></div> -->
          </div>
          <div class="mon_right">
            <!-- <img src="../../assets/img/course/icon_1_12.png" alt />
            分享 -->
          </div>
        </div>
        <div class="card_time">
          <div>
            <img src="../../assets/img/course/icon_2_1.png" alt />
            开播时间：{{ live_info.start_time }} - {{ live_info.end_time }}
          </div>
          <div class="card_btn" @click="goLogin" v-if="!user_id">请登录</div>
          <div
            class="card_btn"
            @click="goTo()"
            v-if="user_id && live_info.is_into == 0"
          >
            立即预约
          </div>
          <div
            @click="goToLive(live_info.id)"
            class="card_btn"
            v-if="user_id && live_info.is_into == 1"
          >
            进入直播间
          </div>
        </div>
      </div>
      <div class="card_body">
        <div class="body_left">
          <!-- 课程简介 -->
          <div class="flex1 tabs">
            <div class="tab active2">课程简介</div>
          </div>
          <div class="intro" v-html="live_info.info"></div>
        </div>
        <!-- 课程推荐 -->
        <div class="body_right">
          <div class="course_top">
            <img src="../../assets/img/course/icon_1_13.png" alt />
            课程推荐
          </div>
          <div class="course_body">
            <div
              class="course_item"
              v-for="(item, index) in courseList"
              :key="index"
              @mouseenter="active = index"
              @mouseleave="active = -1"
              @click="changeDetail(item.culum_id, item.type)"
            >
              <vue-hover-mask>
                <!-- 默认插槽 -->
                <img class="img" :src="item.alias_litpic" alt />
                <!-- action插槽 -->
                <template v-slot:action>
                  <i class="icon-bianji-copy el-icon-video-play"></i>
                </template>
              </vue-hover-mask>
              <div class="rt">
                <div :class="{ active: active == index }">
                  {{ item.alias_name }}
                </div>
                <div>已有{{ item.alias_purchase_num }}人学习</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import VueHoverMask from "@/components/VueHoverMask";
import { liveInfo, appointment, recommend } from "../../assets/js/course";
export default {
  components: {
    VueHoverMask,
  },
  data() {
    return {
      user_id: JSON.parse(window.localStorage.getItem("userInfo"))
        ? JSON.parse(window.localStorage.getItem("userInfo")).id
        : "",
      id: "",
      user_id: "",
      active: -1,
      live_info: {},
      courseList: [],
      dialogVisible: false,
      livePsd: "",
    };
  },
  mounted() {
    if (!this.$route.query.id) {
      this.$router.push("/index");
      return;
    }
    this.id = this.$route.query.id;
    this.getLive();
    this.getrecommend();
  },
  methods: {
    handleClose() {
      this.livePsd = "";
      this.dialogVisible = false;
    },
    goLogin() {
      this.$router.push({
        path: "/login",
        query: {
          active: 0,
        },
      });
    },
    // 获取课程推荐
    getrecommend() {
      recommend({
        siteId: window.localStorage.getItem("siteId"),
      }).then((res) => {
        // console.log(res);
        if (res.code == 200) {
          this.courseList = res.data;
        }
      });
    },
    // 切换课程详情
    changeDetail(id, type) {
      // this.id = id;
      this.$router.push({ path: "/detail", query: { id: id, active2: type } });
      // window.localStorage.setItem("perHeader", 1);
    },
    getLive() {
      this.user_id = JSON.parse(window.localStorage.getItem("userInfo"))
        ? JSON.parse(window.localStorage.getItem("userInfo")).id
        : "";
      liveInfo({
        siteId: window.localStorage.getItem("siteId"),
        id: this.id,
        user_id: this.user_id,
      }).then((res) => {
        // console.log(res);
        if (res.code == 200) {
          this.live_info = res.data.info;
        }
      });
    },
    // 立即预约
    goYY(id) {
      if (!this.livePsd.trim()) {
        this.$message.error("请输入房间密码");
        return;
      }
      appointment({
        id: id,
        password: this.livePsd,
        user_id: this.user_id,
      }).then((res) => {
        // console.log(res);
        this.$message.success("预约成功");
        this.dialogVisible = false;
        this.getLive();
      });
    },
    goTo() {
      if (!this.user_id) {
        setTimeout(function () {
          this.$router.push({ path: "/login", query: { active: 0 } });
        }, 2000);
        return;
      }
      if (this.live_info.is_admission == 0) {
        this.dialogVisible = true;
      }
      if (this.live_info.is_admission == 1) {
        this.$router.push({
          path: "/establish",
          query: { culum_id: this.id, type: 3 },
        });
      }
    },
    // 进入直播间
    goToLive(id) {
      this.$router.push({
        path: "/video2",
        query: {
          id: id,
        },
      });
    },
  },
};
</script>